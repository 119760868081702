import { render, staticRenderFns } from "./Photographer.vue?vue&type=template&id=805317dc&scoped=true&"
import script from "./Photographer.vue?vue&type=script&lang=js&"
export * from "./Photographer.vue?vue&type=script&lang=js&"
import style0 from "./Photographer.vue?vue&type=style&index=0&id=805317dc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "805317dc",
  null
  
)

export default component.exports